.react-tag-input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
  background: white;
  border: 1px solid #cbd5e1;
  border-radius: 3px;
}

.react-tag-input__tag {
  display: flex;
  align-items: center;
  background-color: #f1f5f9;
  border-radius: 8px;
  padding: 4px 16px;
  gap: 6px;
}

.react-tag-input__tag__remove {
  position: relative;
  height: 1em;
  width: 1em;
  margin-top: 2px;
  font-size: 0.8em;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.react-tag-input__tag__remove::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: " ";
  height: 0.7em;
  width: 0.15em;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.react-tag-input__tag__remove::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: " ";
  height: 0.7em;
  width: 0.15em;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.react-tag-input__input {
  margin-left: 5px;
  height: 100%;
  outline: none;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  flex-grow: 1;
}
