@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Manrope:wght@400;500;600;700&family=Montserrat:wght@400;500;600&family=Poppins:wght@400;500;600;700&family=Urbanist:wght@400;500;600;700;800&family=Mulish:wght@400;500;600&family=Raleway:wght@700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* copied carousel settings  */
/** slick custom style */

.ql-toolbar {
  border: 1px solid #e2e8f0 !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ql-container {
  border: 1px solid #e2e8f0 !important;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
